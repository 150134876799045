<template>
    <div class="content">

        <div class="back button" @click="$router.push({ name: 'Register' });">
            Wróć do rejestracji
        </div>

        <div class="content-inside">

            <h2>Zgoda na przetwarzanie danych osobowych dla celów realizacji Konkursu</h2>

            <p>Wyrażam zgodę na przetwarzanie moich danych osobowych, przez Administratora oraz odbiorc&oacute;w danych, na zasadach, o kt&oacute;rych mowa w Regulaminie Konkursu &bdquo;<strong>BE BRILLIANT WITH ALCON&rdquo;</strong>, przez Administratora danych, tj. Alcon Polska Sp. z o.o. z siedzibą w Warszawie, ul. Marynarska 15, 02-674 Warszawa. Moje dane osobowe będą przetwarzane wyłącznie dla cel&oacute;w organizacji i przeprowadzenia Konkursu &bdquo;<strong>BE BRILLIANT WITH ALCON</strong>&rdquo; oraz wydania nagrody. Zgoda może być wycofana w dowolnym momencie poprzez wysłanie informacji na adres e-mail: <a href="mailto:ochrona.danych-alcon@alcon.com">ochrona.danych-alcon@Alcon.com</a> . Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej wycofaniem. Przyjmuję do wiadomości, iż wyrażenie zgody ma charakter dobrowolny, jednak brak mojej zgody uniemożliwia mi udział w Konkursie &bdquo;<strong>BE BRILLIANT WITH ALCON</strong>&rdquo;.</p>


        </div>
    </div>
</template>

<script>
    export default {
        name: 'Data'
    }
</script>
